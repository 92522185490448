import React from "react";
import { Container } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

import "./banner.scss";

const Banner = (props) => {
  return (
    <div className="banner">
      <img
        src={props.data.TopCurveImage.publicURL}
        className="banner-top-image"
        alt="background"
        width="100%"
      ></img>
      <Container>
        <section>
          <ReactMarkdown source={props.data.Content} />
        </section>
      </Container>
      <img
        src={props.data.BottomCurveImage.publicURL}
        className="banner-bottom-image"
        width="100%"
        alt="background"
      ></img>
    </div>
  );
};

export default Banner;
