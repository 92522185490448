import React from "react";
import { Container } from "react-bootstrap";
import SectionHeader from "./sectionHeader";
import caseStudyIcon from "../assets/icons/icon-case-study.png";
import { Link } from "gatsby";
import { Fade, Zoom } from "react-reveal";

import "./success.scss";

function SuccessStory(props) {
  console.log(props.data);
  return (
    <Container className="success hash-section">
      <Fade bottom>
        <SectionHeader
          icon={caseStudyIcon}
          title={props.data.Title}
          highlight={props.data.TitleHighlight}
        />
        <p style={{ fontWeight: 300 }}>{props.data.Content}</p>

        <p>{props.data.BottomContent} </p>
        <Link to={props.data.Link.LinkTo} className="btn btn-thick">
          {props.data.Link.Text}
        </Link>
      </Fade>
    </Container>
  );
}

export default SuccessStory;
