import React from "react";
import Benefit from "./benefit";
import SectionHeader from "../components/sectionHeader";
import { Container, Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import "./benefits.scss";
function PeaceOfMind(props) {
  return (
    <Container className="benefits hash-section">
      <SectionHeader icon="" title="Peace of " highlight="Mind" />
      <Row className="justify-content-center peaceofmind mt-5">
        <Col>
          <div className="h-100">
            <h3>30,000+ Organization</h3>
            <p>#1 Global ERP Platform</p>
          </div>
        </Col>
        <Col>
          <div className="h-100">
            <h3>
              40,000 <br />
              Hrs
            </h3>
            <p>Implementation Experience</p>
          </div>
        </Col>
        <Col>
          <div className="h-100">
            <h3>Single Point</h3>
            <p>Accountablity</p>
          </div>
        </Col>
        <Col>
          <div className="h-100">
            <h3>210%</h3>
            <p>Avg. ROI within 5 years</p>
          </div>
        </Col>
        <Col>
          <div className="h-100">
            <h3>Gartner Quadrent</h3>
            <p>Best Vision, Best Execution</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default PeaceOfMind;
