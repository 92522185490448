import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ReactMarkdown from "react-markdown";
import { useStaticQuery, graphql } from "gatsby";
import heroStyles from "./hero.module.scss";
import "../styles/hero.scss";

const Hero = (props) => {
  const heroData = props.data;

  return (
    <section className="hero xyz-in">
      <Container className={heroStyles.container}>
        <Row>
          <Col xyz="fade left stagger delay-10" className="col-lg-5">
            {/* <ReactMarkdown className="xyz-nested" source={heroData.HeaderH1}  />           */}
            {/* <h1 className="xyz-nested">We partner with your school to help you <span className="highlight">enrol, collect and plan smart.</span></h1> */}
            <h1
              className="xyz-nested"
              dangerouslySetInnerHTML={{ __html: heroData.HeaderH1 }}
            />
            {/* feoda
              <br /> Future Ready Finance
              <br />
              <span className="highlight">for Education</span> */}

            <h4 className="xyz-nested">{heroData.HeaderH4}</h4>
            <h5 className="xyz-nested">{heroData.HeaderH5}</h5>
            <p className="xyz-nested">{heroData.Content}</p>
            <div className="xyz-nested">
              <AnchorLink
                to={heroData.AnchorLink.Url}
                className="btn btn-thick"
              >
                {heroData.AnchorLink.Text}
              </AnchorLink>
            </div>
          </Col>
          <Col xyz="fade right stagger delay-10" className="col-lg-7">
            <img src={heroData.HeroImage.publicURL}></img>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
