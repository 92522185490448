import React, { useEffect } from 'react'
import './sidenav.scss'

const SideNav = () => {

    useEffect(() => {        
        
        let links = document.querySelectorAll(".side-nav .nav-link");

        window.onscroll = () => {
            let fromTop = window.pageYOffset + 68;

            links.forEach((link) => {
                let section = document.querySelector(link.hash);

               

                if(section !== null){

                    if (
                        
                        section.offsetTop <= fromTop &&
                        section.offsetTop + section.offsetHeight > fromTop
                    ) {
                        link.classList.add("active");
                    } else {
                        link.classList.remove("active");
                    }
                }
            })
        }
    }, [])


    return (
        <nav className="side-nav" >
           <section>
                <li className="nav-item">
                    <a className="nav-link" href="#hero"> </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#challenge"> </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#features"> </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#benefits"> </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#success"> </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#book"> </a>
                </li>
           </section>
        </nav>
    )
}

export default SideNav
