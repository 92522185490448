import React from "react";
import "./imageDisplay.scss";
import divide from '../assets/divide.png'



import Img from "gatsby-image";

import { Container } from "react-bootstrap";


function ImageDisplay(props) {
  return (
    <Container>
  
      <img src={divide} className="before1"></img> 
      <div className="image-display">
          
        <Img
          fixed={props.data.Image1.childImageSharp.fixed}
          alt={props.data.Image1Title}
        ></Img>
        <Img
          fixed={props.data.Image2.childImageSharp.fixed}
          alt={props.data.Image2Title}
        ></Img>
        <Img
          fixed={props.data.Image3.childImageSharp.fixed}
          alt={props.data.Image3Title}
        ></Img>
      </div>
    </Container>
  );
}

export default ImageDisplay;
