import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ChallengeCard from "./challengeCard";
import SectionHeader from "../sectionHeader";
import Pulse from "react-reveal/Pulse";
import { Zoom } from "react-reveal";
import ReactMarkdown from "react-markdown";
import "./challenge.scss";
import challengebg from "../../assets/challenge-solution.png";
import challenge1 from "../../assets/challenge1.png";
import challenge2 from "../../assets/challenge2.png";
import equals from "../../assets/equal.png";
import plus from "../../assets/plus.png";
import heroStyles from "./hero.module.scss";
import "./hero1.scss";

function Challenge(props) {
  const cards =
    typeof document !== `undefined`
      ? document.querySelectorAll(".challenge-card")
      : null;

  const challangeData = props.data[0];
  console.info(challangeData);
  useEffect(() => {
    for (const card of cards) {
      card.addEventListener("mouseenter", () => {
        card.classList.add("active");
      });

      card.addEventListener("mouseleave", () => {
        card.classList.remove("active");
      });
    }
  }, [cards]);

  return (
    <div className="challenge hash-section">
      <Container>
        <Pulse>
          <Zoom>
            <SectionHeader
              icon={challangeData.Icon.publicURL}
              title={challangeData.Title}
              highlight={challangeData.Highlight}
              title1={challangeData.Title1}
              text={challangeData.challengeGroup.Content}
            />
          </Zoom>
        </Pulse>

        <section className="hero xyz-in"></section>

        <section className="challenge-cards__wrapper">
          {/* <img className="challenge1" src={challenge1} alt="background"></img>
          <img className="plus_image" src={plus} alt="background" ></img>
          <img className="challenge2" src={challenge2} alt="background" ></img>
          <img className="equal_image" src={equals} alt="background" ></img>
          <img className="challenge3" src={challenge1} alt="background" ></img> */}
          <Row
            className="challenge-cards justify-content-center"
            xyz="fade up stagger delay-6"
          >
            {challangeData.ChallengeCard.map((data) => (
              <ChallengeCard
                key={data.Number}
                //number={data.Number}
                headline={data.Headline}
                content={data.Content}
                hoverTitle={data.HoverTitle}
                hoverHighlight={data.HoverHighlight}
                hoverHighlight1={data.HoverHighlight1}
                hoverHighlight2={data.HoverHighlight2}
                hoverText={data.HoverText}
                hoverText1={data.HoverText1}
                hoverText2={data.HoverText2}
                linkToFeature={data.LinkToFeature}
                challengeContent={data.challengeContent}
                link0={data.link0}
                link1={data.link1}
                link2={data.link2}
              />
            ))}
          </Row>
        </section>
        <Container
          className={heroStyles.container}
          style={{ marginBottom: "5rem" }}
        >
          {/* <Row>
            <Col lg xyz="fade left stagger delay-10">

              <h5 className="main">
                feoda <span className="main1">Flow</span>
                <p className="main2">For Enrolment Heads & Registars</p>
              </h5>
              <h5 className="main">
                feoda <span className="main1">Finance</span>
                <p className="main2">For Business Managers & Bursars</p>
              </h5>
              <h5 className="main">
                feoda <span className="main1">Fusion</span>
                <p className="main2">For Executive Management</p>
              </h5>
            </Col>
          </Row> */}
        </Container>

        <section className="challenge-group">
          {/*<p>Online Applications <span>&amp; future student CRM:</span></p>                 */}
          {/*<p>Equip registrars with the tools to build parent <br/>engagement while reducing administrative burden. </p> */}
          {/*<Row>
            <Col sm={3}>
              <h4 className="highlight">
                {challangeData.challengeGroup.Title1}
              </h4>
            </Col>
            <Col sm={9}>
              <h4 className="highlight">
                {challangeData.challengeGroup.Title2}
              </h4>
            </Col>
          </Row>*/}

          {/*<ReactMarkdown source={challangeData.challengeGroup.Content} />/}
          {/* <p>
            WE OFFER <span className="highlight">ONE, UNIFIED SOLUTION</span>{" "}
            THAT SIMPLIFIES MULTIPLE WORKLOADS.
          </p> */}
        </section>
      </Container>
    </div>
  );
}

export default Challenge;
