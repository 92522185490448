import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import "./features.scss";
import { Zoom } from "react-reveal";
import ReactMarkdown from "react-markdown";
import SectionHeader from "../sectionHeader";

function Features(props) {
  return (
    <div className="features hash-section">
      <Container>
        <Zoom>
          <SectionHeader
            title={props.data.Title}
            highlight={props.data.Highlight}
          />
          <ReactMarkdown source={props.data.Content} />
        </Zoom>
      </Container>

      <Container fluid className="features-wrapper">
        <Row>
          {props.data.featureItem.map((feature) => {
            return (
              <Col key={feature.Title} md>
                {/* <Link to={feature.Link} >  */}
                {feature.Title != "Maintain." ? (
                  <div className="features-card">
                    <img
                      src={feature.Icon && feature.Icon.publicURL}
                      alt="icon"
                      width="35"
                    ></img>
                    <h3 className="mt-4">{feature.Title}</h3>
                    {/* <h5>{feature.Subtitle}</h5>             */}
                    <h3 className="highlight">{feature.Subtitle}</h3>
                  </div>
                ) : (
                  <div className="features-card margin-left-maintain">
                    <img
                      src={feature.Icon.publicURL}
                      alt="icon"
                      width="35"
                    ></img>
                    <h3 className="highlight">{feature.Title}</h3>{" "}
                    <h5>{feature.Subtitle}</h5>
                    <ReactMarkdown source={feature.Content} />
                  </div>
                )}
                {/* </Link>  */}
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default Features;
