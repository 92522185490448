import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Challenge from "../components/challenges/challenge";
import ImageDisplay from "../components/imageDisplay";
import Features from "../components/features/features";
import Benefits from "../components/benefits";
import PeaceOfMind from "../components/peaceofmind";
import Banner from "../components/banner";
import SuccessStory from "../components/successStory";
import SideNav from "../components/sideNavigation/SideNav";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  const homePageData = useStaticQuery(graphql`
    query {
      allStrapiHomePage {
        nodes {
          Banner {
            BottomCurveImage {
              publicURL
            }
            Content
            TopCurveImage {
              publicURL
            }
          }
          Benefit {
            BenefitsTab {
              BenefitIconImage {
                publicURL
              }
              Content
              EventKey
              Title
              id
            }
            SliderImage {
              publicURL
            }
            SliderImage1 {
              publicURL
            }
            Tab {
              EventKey
              Title
              id
              FadeFromPosition
            }
            Title
            TitleHighlight
            id
          }
          Hero {
            AnchorLink {
              Text
              Url
              id
            }
            Content
            HeaderH1
            HeaderH4
            HeaderH5
            HeroImage {
              publicURL
            }
          }
          HomeChallenge {
            ChallengeCard {
              Content
              Headline
              HoverHighlight
              HoverText
              HoverTitle
              LinkToFeature
              Number
              HoverHighlight1
              HoverText1
              HoverHighlight2
              HoverText2
              challengeContent
              link1
              link2
              link0
            }
            Highlight
            Icon {
              publicURL
            }
            Text
            Title
            Title1
            challengeGroup {
              Content
              Title1
              Title2
              id
            }
          }
          HomeFeature {
            Content
            Highlight
            Text
            Title
            featureItem {
              Content
              Icon {
                publicURL
              }
              Link
              Subtitle
              Title
            }
          }
          ImageDisplays {
            Image1 {
              publicURL
              childImageSharp {
                sizes {
                  base64
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
                fluid(maxWidth: 350, maxHeight: 266) {
                  ...GatsbyImageSharpFluid
                }
                fixed {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
            Image1Title
            Image2 {
              publicURL
              childImageSharp {
                sizes {
                  base64
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
                fluid(maxWidth: 350, maxHeight: 266) {
                  ...GatsbyImageSharpFluid
                }
                fixed {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
            Image2Title
            Image3 {
              publicURL
              childImageSharp {
                sizes {
                  base64
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
                fluid(maxWidth: 350, maxHeight: 266) {
                  ...GatsbyImageSharpFluid
                }
                fixed {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
            Image3Title
          }
          PageTitle
          SuccessStory {
            BottomContent
            Content
            Link {
              LinkTo
              Text
              id
            }

            Title
            TitleHighlight
            id
          }
        }
      }
    }
  `);

  const heroData = homePageData.allStrapiHomePage.nodes[0];

  return (
    <Layout>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>{heroData.PageTitle} </title>
        {/* <link rel="canonical" href="https://feoda.com/" />*/}
        <link rel="canonical" href="http://localhost:1337" />
      </Helmet>

      <div className="main" style={{ "scroll-behavior": "smooth" }}>
        <div id="hero">
          <Hero data={heroData.Hero} />
        </div>

        <div id="challenge">
          <Challenge data={heroData.HomeChallenge} />
        </div>
        <div id="features">
          <Features data={heroData.HomeFeature} />
        </div>
        <ImageDisplay data={heroData.ImageDisplays} />

        {/* <div id="benefits">
          <Benefits data={heroData.Benefit} />
        </div> */}
        <div id="benefits">
          <PeaceOfMind data={heroData.Benefit} />
        </div>
        <div id="success">
          <Banner data={heroData.Banner} />
          <SuccessStory data={heroData.SuccessStory} />
        </div>

        <SideNav />
      </div>
    </Layout>
  );
};

export default IndexPage;
