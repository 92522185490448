import React from "react";
import rightArrow from "../../assets/icons/icon-arrow-right.png";
import Reveal from "react-reveal/Pulse";
import { Link } from "gatsby";
import challenge1 from "../../assets/challenge1.png";
import challenge2 from "../../assets/ch2.png";
import challenge3 from "../../assets/ch3.png";
import plus from "../../assets/plus.png";
import equals from "../../assets/equal.png";
import "./hero1.scss";

const ChallengeCard = (props) => {
  return (
    <div className="col-sm-12 col-md-3">
      <div
        className={
          props.headline == "Accelarating"
            ? "challenge1 challenge-bg"
            : props.headline == "Make fee management"
            ? "challenge2 challenge-bg"
            : props.headline == "Streamline procument"
            ? "challenge3 challenge-bg"
            : "challenge1 chal4 challenge-bg"
        }
      >
        {props.headline == "Accelarating" ? (
          <img
            className="plus_image d-none d-md-block"
            src={plus}
            alt="background"
          ></img>
        ) : (
          ""
        )}
        {props.headline == "Streamline procument" ? (
          <img
            className="equal_image  d-none d-md-block"
            src={equals}
            alt="background"
          ></img>
        ) : (
          ""
        )}
        <div
          className={
            props.headline == "Streamline procument"
              ? "challenge-card "
              : "challenge-card "
          }
        >
          <span className="number">{props.number}</span>
          <p>
            <span className="highlight">{props.headline}</span>
            <br />
            {props.content}
          </p>
          <img src={rightArrow} alt="right arrow" width="25"></img>
        </div>

        <Reveal effect="fadeInUp" effectOut="fadeOutLeft">
          <div className="challenge-card__content">
            <div>
              <h5>
                {" "}
                <Link to={props.link0}>{props.hoverHighlight}</Link>
              </h5>
              &nbsp;
              <p>{props.hoverText}</p>
              <h5 className="highlight">
                <Link to={props.link1}>{props.hoverHighlight1}</Link>
              </h5>
              &nbsp;
              <p>{props.hoverText1}</p>
              <h5 className="highlight">
                <Link to={props.link2}>{props.hoverHighlight2}</Link>
              </h5>
              &nbsp;
              <p>{props.hoverText2}</p>
            </div>
          </div>
        </Reveal>
        <div
          className="challengeContent"
          dangerouslySetInnerHTML={{
            __html: props.challengeContent,
          }}
        />
      </div>
    </div>
  );
};

export default ChallengeCard;
